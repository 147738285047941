<template>
    <div class="wrapper">
        <div class="total-box-1">
            <admin-title title="实时概况" bottom="0px"></admin-title>
            <div class="content">
                <div class="item animate__animated animate__backInLeft animate__faster">
                    <div class="left">
                        <i class="el-icon-coin"></i>
                    </div>
                    <div class="title">
                        <span class="num"> {{ (overview.orderTotalPrice && overview.orderTotalPrice.tday) || 0 }}</span>
                        <span class="label">销售额（元）</span>
                        <div class="sub-title">昨日+{{ (overview.orderTotalPrice && overview.orderTotalPrice.ytd) || 0 }}</div>
                    </div>
                </div>
                <div class="item animate__animated animate__backInLeft animate__faster">
                    <div class="left">
                        <i class="el-icon-tickets"></i>
                    </div>
                    <div class="title">
                        <span class="num"> {{ (overview.orderTotal && overview.orderTotal.tday) || 0 }}</span>
                        <span class="label">支付订单数</span>
                        <div class="sub-title">昨日+{{ (overview.orderTotal && overview.orderTotal.ytd) || 0 }}</div>
                    </div>
                </div>
                <div class="item animate__animated animate__backInRight animate__faster">
                    <div class="left">
                        <i class="el-icon-user"></i>
                    </div>
                    <div class="title">
                        <span class="num"> {{ (overview.userTotalCount && overview.userTotalCount.tday) || 0 }}</span>
                        <span class="label">跟进会员数</span>
                    </div>
                </div>
            </div>
        </div>

        <div style="margin-top: 20px" class="chart-box">
            <admin-title title="近七日交易走势" bottom="0px"></admin-title>
            <LineChartMult
                v-if="show"
                title=""
                :list_x="tradeTrend.days"
                :list_y1="tradeTrend.order_count"
                :list_y2="tradeTrend.order_sum_price"
                :legend="['成交量', '成交额']"
            ></LineChartMult>
        </div>
    </div>
</template>

<script>
import LineChart from './charts/line-chart.vue'
import LineChartMult from './charts/line-chart-mult.vue'
import { getIndexStatAPI } from './api'

export default {
    name: 'Home',
    components: { LineChart, LineChartMult },
    data() {
        return {
            show: false,
            stat: {},
            overview: {},
            pending: {},
            tradeTrend: {}
        }
    },
    computed: {},

    mounted() {
        this.getIndexStat()
    },

    methods: {
        async getIndexStat() {
            const res = await getIndexStatAPI()
            this.show = true
            this.overview = res.overview || {}
            this.stat = res.statistics || {}
            this.tradeTrend = res.tradeTrend || {}
        }
        // 发布商品
    }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
    width: 100%;
    height: 120vh;
    // background-color: #f0f2f5;
    .total-box-1 {
        background: #fff;
        padding: 20px;
        margin-bottom: 20px;
        .content {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            gap: 20px;

            justify-content: center;

            .item {
                // cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #fff;
                padding: 30px 0;
                // box-shadow: 0 0px 8px 0 rgba(114, 114, 114, 0.1);
                border-radius: 4px;
                transition: all 0.2s linear;

                .left {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 30px;
                    width: 58px;
                    height: 58px;
                    background-color: #eff6ff;
                    border-radius: 50%;
                    i {
                        font-size: 30px;
                        color: #1890ff;
                    }
                }
                .title {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .label {
                        font-size: 14px;
                    }
                    .num {
                        font-weight: 700;
                        font-size: 18px;
                    }
                    .sub-title {
                        position: absolute;
                        right: 0;
                        top: 0;
                        transform: translate(90%, -80%);
                        white-space: nowrap;
                        background-color: #0571f2;
                        line-height: 20px;
                        padding: 0 10px;
                        border-radius: 20px;
                        color: #fff;
                        font-size: 14px;
                        font-weight: 400;
                    }
                }
            }
        }
    }
    .total-box {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 20px;
        // padding: 20px 0;
        justify-content: center;
        .item {
            // cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            padding: 30px 0;
            // box-shadow: 0 0px 8px 0 rgba(114, 114, 114, 0.1);
            border-radius: 2px;
            transition: all 0.2s linear;

            .left {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 30px;
                width: 58px;
                height: 58px;
                background-color: #eff6ff;
                border-radius: 50%;
                i {
                    font-size: 30px;
                    color: #1890ff;
                }
            }
            .title {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                .label {
                    font-size: 14px;
                }
                .num {
                    font-weight: 700;
                    font-size: 18px;
                }
                .sub-title {
                    position: absolute;
                    right: 0;
                    top: 0;
                    transform: translate(90%, -80%);
                    white-space: nowrap;
                    background-color: #0571f2;
                    line-height: 20px;
                    padding: 0 10px;
                    border-radius: 20px;
                    color: #fff;
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }
    }
    .count-box {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        justify-content: center;
        gap: 20px;
        background: #fff;
        margin: 20px 0;
        .item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 40px 0;
            &:hover {
                cursor: pointer;
                .label {
                    color: #0571f2;
                }
            }
            .label {
                font-weight: 700;
            }
            .value {
                font-size: 16px;
                color: #f56c6c;
                font-weight: 700;
            }
        }
    }
    .utils-box {
        background: #fff;
        margin-bottom: 20px;
        padding: 20px;
        .content {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            gap: 20px;
            justify-content: center;
            .item {
                display: flex;
                align-items: center;
                // justify-content: center;
                padding-left: 27%;

                &:hover {
                    cursor: pointer;
                    .right {
                        color: #0571f2;
                    }
                }
                .left {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 28px;
                    height: 28px;
                    border-radius: 3px;
                    margin-right: 10px;

                    i {
                        font-size: 15px;
                        color: #fff;
                    }
                }
                .blue {
                    background-color: #0571f2;
                }
                .green {
                    background-color: #00bf57;
                }
                .red {
                    background-color: #f15858;
                }
                .orange {
                    background: #e6a23c;
                }
            }
        }
    }
    .chart-box {
        padding: 20px;
        background: #fff;
    }
}
</style>
